/* eslint-disable */
import * as React from 'react';
import { Tooltip, Layout, message, Button } from 'antd';
import * as MockStyles from './styles/index.module.less';
import './styles/index.less';
import ViewerOverlord from '../viewer';
import DummyHeader from './dummy_header';
import DummyContent from './dummy_content';
import { IDENTIFIERS, LOCALES } from '../../config';
import contentOverlord from '../../config/content_overlord';
import Preloader from './preloader';
import MG1 from './mg1';
import Controls from './dummy_content/controls';
import Layers from './layers';
import Logo from './dummy_header/logo';
import { RetweetOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
interface Props {
  debug: boolean;
}
export default function MockLayout({ debug }: Props) {
  const [percent, setPercent] = React.useState();
  const [lockCamera, setLockCamera] = React.useState(true); // N.B: `OrbitControls` has  `this.lockCamera = true`
  const [noclip, setNoclip] = React.useState(true); // N.B: `OrbitControls` has  `this.lockCamera = true`
  const [followContent, setFollowContent] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [hasInit, setHasInit] = React.useState(false);
  const [selectedObject, setSelectedObject] = React.useState();
  React.useEffect(() => {
    if (hasInit) return;
    message.warning({
      content: LOCALES.MAIN_LAYOUT.CONTENT.MISC.LOADING_MESSAGE,
      className: 'custom-class',
      style: {
        marginTop: '50vh',
      },
    });

    const viewer = new ViewerOverlord();
    setTimeout(async () => {
      await viewer.init(debug);
    }, 100);
    viewer.setPercent = setPercent;
    viewer.setSelectedObject = setSelectedObject;
    viewer.setHasInit = setHasInit;
    // eslint-disable-next-line
    return () => {};
  }, []);

  const { animationContent } = contentOverlord; // All animation content
  const contents = Object.values(animationContent); // As `array`
  const content = contents[contents.length - 1]; // Assume only one animation is active
  const { selectableObjects } = content;
  // eslint-disable-next-line
  let selectedObjectName: string = undefined;
  selectableObjects.forEach((i) => {
    if (i.id !== selectedObject) return;
    selectedObjectName = i.name;
  });

  return (
    <div className={MockStyles.app}>
      {!hasInit && <Preloader percent={percent} />}
      {hasInit ? (
        <>
          <title>{selectedObjectName} | Vossloh Planner</title>
        </>
      ) : (
        <>
          <title>Laddar... | Vossloh Planner</title>
        </>
      )}
      <div id={IDENTIFIERS.RENDER_AREA} className={MockStyles.container} />
      <Layers />
      <div className={MockStyles.logoFixedContainer}>
        <Logo logo={MockStyles.logoFixed} />
      </div>
      <div className={MockStyles.controlsMock}>
        <Controls
          lockCamera={lockCamera}
          setLockCamera={setLockCamera}
          noclip={noclip}
          setNoclip={setNoclip}
          followContent={followContent}
          setFollowContent={setFollowContent}
          selectedObjectName={selectedObjectName}
        />
      </div>
      <div
        style={{
          backgroundImage: `url('/static/mock/lg1.png')`, // N.B: Add background image dynamically (and not hard code in the LESS)
        }}
        className={MockStyles.lg1}
      ></div>
      <div className={MockStyles.mg1}>
        <MG1 />
      </div>
      <div
        style={{
          backgroundImage: `url('/static/mock/rg1.png')`, // N.B: Add background image dynamically (and not hard code in the LESS)
        }}
        className={MockStyles.rg1}
      ></div>
      <div className={MockStyles.resetBtn}>
        <Tooltip placement="bottom" title="Ladda om scenario">
          <Button
            onClick={() => {
              if (ViewerOverlord.getInstance() === undefined) return;
              ViewerOverlord.getInstance().resetSequence();
            }}
            shape="round"
            icon={<RetweetOutlined />}
          />
        </Tooltip>
      </div>
    </div>
  );
}
