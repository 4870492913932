/* eslint-disable */
import * as React from 'react';
import * as MockStyles from './index.module.less';
import { Donut } from 'react-dial-knob';
import { Knob } from 'react-rotary-knob';
import * as skins from 'react-rotary-knob-skin-pack';
import knobSkin from './knob';
import ViewerOverlord from '../../viewer';

function MG1() {
  const org = 100;
  const limit = 40;
  const [value, setValue] = React.useState(org);

  if (ViewerOverlord.getInstance() !== undefined) {
    const _setValue = (i) => {
      if (i === 0) {
        setValue(org);
      }
    };
    ViewerOverlord.getInstance().setPlaybackSpeedEvent = _setValue;
  }

  const convertToViewer = (i: number) => {
    const isPositive: number = i >= 0 ? -1 : 1;
    let playbackSpeed = (1 - Math.abs(i / org)) * isPositive;
    ViewerOverlord.getInstance().setPlaybackSpeed(playbackSpeed * 4);
  };
  return (
    <>
      <div className={MockStyles.knob}>
        {/* <Donut
          diameter={50}
          min={-100}
          max={100}
          step={1}
          value={value}
          theme={{
            donutColor: '#33AE67',
            donutThickness: 10,
          }}
          onValueChange={(i) => {
            if (Math.abs(i) < limit) {
              return;
              // setValue(i);
            } else {
              if (ViewerOverlord.getInstance() === undefined) return;
              setValue(i);
              convertToViewer(i);
            }
          }}
        ></Donut> */}
        <Knob
          unlockDistance={5}
          skin={knobSkin}
          min={-100}
          max={100}
          step={1}
          value={value}
          preciseMode={false}
          onChange={(i) => {
            if (Math.abs(i) < limit) {
              return;
              // setValue(i);
            } else {
              if (ViewerOverlord.getInstance() === undefined) return;
              setValue(i);
              convertToViewer(i);
            }
          }}
        />
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="233"
        height="88"
        fill="none"
        viewBox="0 0 233 88"
      >
        <g filter="url(#filter0_d_120139_171697)" opacity="0.8">
          <rect width="224.717" height="80" x="4" fill="#fff" rx="10"></rect>
        </g>
        <svg
          onClick={() => {
            if (ViewerOverlord.getInstance() === undefined) return;
            ViewerOverlord.getInstance().setPlaybackSpeed(0);
            setValue(org);
          }}
          className={MockStyles.stop}
        >
          <path
            fill="#CA2A2A"
            stroke="#000"
            d="M134.217 27.5H174.217V46.5H134.217z"
          ></path>
        </svg>
        {/* <svg
          onClick={() => {
            alert('stopping');
          }}
          className={MockStyles.playback}
        >
          <g filter="url(#filter1_d_120139_171697)">
            <circle cx="80.413" cy="36.5" r="21.5" fill="#33AE67"></circle>
            <circle cx="80.413" cy="36.5" r="21" stroke="#000"></circle>
          </g>
          <g filter="url(#filter2_d_120139_171697)">
            <path
              fill="#C4C4C4"
              d="M108.821 28.119c2.436-.224 3.066 3.285.704 3.922l-52.547 14.18a2 2 0 01-2.497-1.624l-1.436-9.227a2 2 0 011.793-2.299l53.983-4.952z"
            ></path>
            <path
              stroke="#000"
              d="M109.394 31.558l-52.546 14.18a1.5 1.5 0 01-1.873-1.218l-1.436-9.226a1.5 1.5 0 011.345-1.725l53.982-4.953c1.828-.167 2.3 2.464.528 2.942z"
            ></path>
            <path
              stroke="#000"
              strokeOpacity="0.2"
              d="M109.394 31.558l-52.546 14.18a1.5 1.5 0 01-1.873-1.218l-1.436-9.226a1.5 1.5 0 011.345-1.725l53.982-4.953c1.828-.167 2.3 2.464.528 2.942z"
            ></path>
          </g>
        </svg> */}

        <path
          fill="#fff"
          d="M145.039 39.135c0-.277-.098-.488-.293-.635-.196-.15-.547-.306-1.055-.469a7.588 7.588 0 01-1.206-.488c-.807-.436-1.211-1.024-1.211-1.763 0-.384.107-.726.322-1.025.218-.303.529-.539.933-.708.407-.17.862-.254 1.367-.254.508 0 .96.093 1.357.278.398.183.705.441.923.777.222.335.332.716.332 1.142h-1.465c0-.325-.102-.578-.307-.757-.205-.182-.493-.273-.864-.273-.358 0-.637.076-.835.23a.709.709 0 00-.298.595c0 .231.115.425.346.581.235.157.578.303 1.031.44.833.25 1.44.561 1.821.932s.571.834.571 1.387c0 .615-.232 1.099-.698 1.45-.465.348-1.092.523-1.88.523-.547 0-1.045-.1-1.494-.298-.449-.202-.793-.477-1.03-.825a2.113 2.113 0 01-.352-1.211h1.47c0 .784.469 1.176 1.406 1.176.348 0 .62-.07.816-.21a.698.698 0 00.293-.595zm7.817-4.058h-2.178V41h-1.465v-5.923h-2.148v-1.186h5.791v1.186zm6.543 2.53c0 .7-.124 1.313-.371 1.84a2.79 2.79 0 01-1.065 1.221c-.459.286-.986.43-1.582.43a2.957 2.957 0 01-1.577-.425 2.827 2.827 0 01-1.074-1.211c-.254-.527-.383-1.133-.386-1.817v-.351c0-.7.126-1.315.376-1.846.254-.534.611-.942 1.07-1.225.462-.287.989-.43 1.582-.43.592 0 1.118.143 1.577.43.462.283.818.691 1.069 1.225.254.53.381 1.144.381 1.841v.317zm-1.484-.323c0-.745-.134-1.312-.401-1.699s-.648-.581-1.142-.581c-.492 0-.871.192-1.138.576-.267.38-.402.94-.405 1.68v.346c0 .726.133 1.29.4 1.69.267.4.651.6 1.152.6.492 0 .869-.192 1.133-.576.264-.387.397-.95.401-1.69v-.346zm4.013 1.211V41h-1.465v-7.11h2.774c.534 0 1.002.098 1.406.294.407.195.719.473.938.835.218.358.327.766.327 1.225 0 .697-.24 1.247-.718 1.65-.475.4-1.134.601-1.978.601h-1.284zm0-1.186h1.309c.387 0 .682-.092.884-.274.205-.182.307-.443.307-.781 0-.348-.102-.63-.307-.845-.205-.215-.489-.325-.85-.332h-1.343v2.232z"
        ></path>
        <defs>
          <filter
            id="filter0_d_120139_171697"
            width="232.717"
            height="88"
            x="0"
            y="0"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dy="4"></feOffset>
            <feGaussianBlur stdDeviation="2"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_120139_171697"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_120139_171697"
              result="shape"
            ></feBlend>
          </filter>
          <filter
            id="filter1_d_120139_171697"
            width="51"
            height="51"
            x="54.913"
            y="15"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dy="4"></feOffset>
            <feGaussianBlur stdDeviation="2"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_120139_171697"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_120139_171697"
              result="shape"
            ></feBlend>
          </filter>
          <filter
            id="filter2_d_120139_171697"
            width="65.986"
            height="26.182"
            x="49.02"
            y="28.108"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dy="4"></feOffset>
            <feGaussianBlur stdDeviation="2"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_120139_171697"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_120139_171697"
              result="shape"
            ></feBlend>
          </filter>
        </defs>
      </svg>
    </>
  );
}

export default MG1;
