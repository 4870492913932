/* eslint-disable */
const knobSkin = {
  knobX: 71,
  knobY: 71,
  svg: `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 204 204" style="enable-background:new 0 0 204 204;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#322E2E;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#33AE67;}
	.st2{fill:none;stroke:#000000;stroke-width:5;}
	.st3{fill:#C4C4C4;}
	.st4{fill:none;stroke:#000000;}
	.st5{fill:none;stroke:#000000;stroke-opacity:0.2;}
</style>
<desc>Created with Sketch.</desc>
<g id="Page-1">
	<g id="s15" transform="translate(4.000000, 4.000000)">
		<g id="_x31_">
			<g id="_x32_" transform="translate(27.431373, 27.431373)">
				<g id="Oval-5">
					<g>
						<circle id="_x37_0f4f9ff-1199-5366-0cca-eb055430a393" class="st0" cx="71" cy="71" r="71"/>
					</g>
					<g>
						
							<circle id="_x37_0f4f9ff-1199-5366-0cca-eb055430a393_00000000182049158765828180000013572904896719041723_" class="st1" cx="71" cy="71" r="71"/>
					</g>
					<g>
						
							<circle id="_x37_0f4f9ff-1199-5366-0cca-eb055430a393_00000150821155460582213150000000467426988472034715_" class="st2" cx="71" cy="71" r="71"/>
					</g>
				</g>
				<g id="knob">
					<path class="st3" d="M61.9-24.3c0.7-8.1,13.9-8.2,14.8-0.1l19.1,180.2c0.4,3.9-3.1,7.3-7.5,7.3l-34.6-0.6
						c-4.3-0.1-7.6-3.4-7.3-7.2L61.9-24.3z"/>
					<path class="st4" d="M63.7-24.2c0.5-6.1,10.4-6.1,11.1-0.1L93.9,156c0.3,3-2.3,5.5-5.6,5.4l-34.6-0.6c-3.2-0.1-5.7-2.5-5.5-5.4
						L63.7-24.2z"/>
					<path class="st5" d="M63.7-24.2c0.5-6.1,10.4-6.1,11.1-0.1L93.9,156c0.3,3-2.3,5.5-5.6,5.4l-34.6-0.6c-3.2-0.1-5.7-2.5-5.5-5.4
						L63.7-24.2z"/>
				</g>
			</g>
		</g>
	</g>
</g>
</svg>

`,
};

export default knobSkin;
