/* eslint-disable */
import * as React from 'react';

function Logo({ logo }: { logo: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="887"
      height="209"
      viewBox="0 0 887 209"
      className={logo}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            fill="#000"
            d="M148.071 61.932h-47.67l-23.834 95.475h-.543L51.646 61.932H.716l44.41 139.274h57.72l45.225-139.274"
          ></path>
          <path
            fill="#231F20"
            d="M407.334 158.697c0-24.31-10.593-32.594-28.18-38.977l-16.298-5.908c-11.611-4.278-16.229-7.47-16.229-12.29 0-7.47 7.877-10.187 17.316-10.187 12.495 0 25.736 4.55 34.7 8.556l1.63-34.7c-10.323-3.463-24.65-6.45-39.25-6.45-42.848 0-58.807 21.118-58.807 45.7 0 17.655 6.52 31.304 28.996 39.317l14.872 5.297c11.951 4.346 15.754 6.994 15.754 11.815 0 6.383-6.79 9.847-20.1 9.847-17.045 0-29.268-6.112-36.805-9.847l-1.087 37.145c7.81 2.92 27.638 6.383 43.053 6.383 41.694 0 60.435-16.84 60.435-45.7"
          ></path>
          <path
            fill="#231F20"
            d="M521.144 158.697c0-24.31-10.593-32.594-28.181-38.977l-16.23-5.908c-11.68-4.278-16.297-7.47-16.297-12.29 0-7.47 7.877-10.187 17.384-10.187 12.427 0 25.736 4.55 34.632 8.556l1.63-34.7c-10.254-3.463-24.65-6.45-39.25-6.45-42.848 0-58.806 21.118-58.806 45.7 0 17.655 6.519 31.304 28.996 39.317l14.87 5.297c11.953 4.346 15.755 6.994 15.755 11.815 0 6.383-6.79 9.847-20.1 9.847-17.044 0-29.2-6.112-36.805-9.847l-1.086 37.145c7.877 2.92 27.638 6.383 43.052 6.383 41.762 0 60.436-16.84 60.436-45.7"
          ></path>
          <path
            fill="#231F20"
            d="M532.28 201.206h48.756V.75H532.28v200.457z"
          ></path>
          <path
            fill="#231F20"
            d="M886.543 201.206v-97.58c0-30.693-18.674-44.885-42.78-44.885-16.501 0-28.996 6.722-37.145 20.847h-.814c.543-5.093 1.086-11.476 1.086-17.113V.75h-48.756v200.457h48.756v-76.461c0-14.396 5.432-26.959 17.655-26.959 9.167 0 13.242 6.383 13.242 19.76v83.66h48.756"
          ></path>
          <path
            fill="#32B53F"
            d="M140.805 131.603c0-32.527 20.1-60.3 48.553-71.64l25.804 47.941-30.014 55.818h38.773l30.015-55.818-28.52-53.034c39.045 3.803 69.535 36.669 69.535 76.733 0 42.577-34.496 77.073-77.073 77.073-42.577 0-77.073-34.496-77.073-77.073"
          ></path>
          <path
            fill="#231F20"
            d="M592.444 130.856c0-42.576 34.496-77.072 77.073-77.072 42.577 0 77.073 34.495 77.073 77.072 0 40.064-30.49 72.93-69.535 76.733l28.52-53.034L675.56 98.67h-38.842l30.082 55.886-25.804 47.941c-28.452-11.34-48.553-39.113-48.553-71.64"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
