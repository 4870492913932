/* eslint-disable @typescript-eslint/indent */
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  HomeOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import * as React from 'react';
import { LOCALES } from '../../../../config';
import ViewerOverlord from '../../../viewer';
import ControlsProps from './props';

export default function Controls({
  lockCamera,
  setLockCamera,
  noclip,
  setNoclip,
  followContent,
  setFollowContent,
  selectedObjectName,
}: ControlsProps) {
  // eslint-disable-next-line
  if (selectedObjectName === undefined) selectedObjectName = '';
  return (
    <>
      <Tooltip
        placement="left"
        title={
          // eslint-disable-next-line
          lockCamera
            ? LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.ONLY_IN_3D
            : followContent
            ? `${
                LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.FOLLOW_NOT
                // eslint-disable-next-line
              } ${selectedObjectName.toLowerCase()}`
            : `${
                LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.FOLLOW
              } ${selectedObjectName.toLowerCase()}`
        }
      >
        <Button
          onClick={() => {
            setFollowContent(!followContent);
            ViewerOverlord.getInstance().updateFollowContent(!followContent);
          }}
          shape="round"
          disabled={lockCamera}
          icon={followContent ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        />
      </Tooltip>
      <Tooltip
        placement="left"
        title={
          lockCamera
            ? LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.SWITCH_TO_3D
            : LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.SWITCH_TO_2D
        }
      >
        <Button
          onClick={() => {
            setLockCamera(!lockCamera);
            ViewerOverlord.getInstance().updateCamera(!lockCamera);
          }}
          shape="round"
          icon={lockCamera ? '2D' : '3D'}
        />
      </Tooltip>
      <Tooltip
        placement="left"
        title={
          noclip
            ? LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.NOCLIP_DISABLE
            : LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.NOCLIP_ENABLE
        }
      >
        <Button
          onClick={() => {
            setNoclip(!noclip);
            ViewerOverlord.getInstance().updateNoclip(!noclip);
          }}
          shape="round"
          icon={
            noclip ? (
              <VerticalAlignMiddleOutlined />
            ) : (
              <VerticalAlignBottomOutlined />
            )
          }
        />
      </Tooltip>
      <Tooltip
        placement="left"
        title={LOCALES.MAIN_LAYOUT.CONTENT.CONTROLS.HOME}
      >
        <Button
          onClick={() => {
            setLockCamera(true);
            ViewerOverlord.getInstance().resetCamera();
          }}
          shape="round"
          icon={<HomeOutlined />}
        />
      </Tooltip>
    </>
  );
}
