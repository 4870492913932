/* eslint @typescript-eslint/indent: 0 */
import { Popover, Tree } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import * as React from 'react';
import { LOCALES } from '../../../config';
import ViewerOverlord from '../../viewer';
import * as Styles from './styles/index.module.less';
// eslint-disable-next-line
export default function Layers({}: any) {
  const objects =
    ViewerOverlord.getInstance()?.visibilityOverlord?.getObjects();

  const treeData =
    objects === undefined
      ? []
      : Object.values(objects).map((i) => {
          return { title: i.userData.name, key: i.userData.id };
        });

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    // eslint-disable-next-line
    console.log('selected', selectedKeys, info);
  };

  const onCheck = (checkedKeys: React.Key[], info: any) => {
    // eslint-disable-next-line
    console.log('onCheck', checkedKeys, info);
    ViewerOverlord.getInstance()?.visibilityOverlord?.setVisibilityForAllObjects(
      false,
    );
    checkedKeys.forEach((key) => {
      ViewerOverlord.getInstance()?.visibilityOverlord?.setVisibility(
        key as string,
        true,
      );
    });
  };
  /**
   *
   */
  const content = (
    <Tree
      defaultCheckedKeys={Object.values(treeData).map((v) => v.key)}
      checkable
      onSelect={onSelect}
      onCheck={onCheck}
      treeData={treeData}
    />
  );
  return (
    <>
      <Popover
        content={content}
        title={LOCALES.MAIN_LAYOUT.CONTENT.LAYERS.TITLE}
      >
        <button type="button" className={Styles.layers2}>
          <div className={Styles.layersContainer2}>
            <div className={Styles.layersMap2} />
            <UnorderedListOutlined />
            <label className={Styles.layersLabel2}>
              {LOCALES.MAIN_LAYOUT.CONTENT.LAYERS.NAME}
            </label>
          </div>
        </button>
      </Popover>
    </>
  );
}
