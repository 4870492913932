import * as React from 'react';
import Typed from 'typed.js';
import { LOCALES } from '../../../../config';

export default function Text() {
  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);
  // Create reference to store the Typed instance itself
  const typed = React.useRef(null);
  const strings = LOCALES.AUTH.LOADING_STRINGS.map((i) => `${i}...`);
  React.useEffect(() => {
    const options = {
      strings,
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 1700,
      loop: true,
      shuffle: true,
    };

    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);

    /**
     * Make sure to destroy `Typed` instance during cleanup to prevent memory leaks.
     */
    return () => {
      typed.current.destroy();
    };
  }, []);
  return (
    <div>
      <h1>
        <span>{LOCALES.AUTH.LOADING}</span>
        <span style={{ whiteSpace: 'pre' }} ref={el} />
      </h1>
    </div>
  );
}
