// extracted by mini-css-extract-plugin
export var container = "omnitwin-c_p";
export var app = "omnitwin-c_b";
export var background = "omnitwin-c_q";
export var lg1 = "omnitwin-c_r";
export var rg1 = "omnitwin-c_s";
export var mg1 = "omnitwin-c_t";
export var controlsMock = "omnitwin-c_v";
export var logoFixedContainer = "omnitwin-c_w";
export var logoFixed = "omnitwin-c_x";
export var resetBtn = "omnitwin-c_y";