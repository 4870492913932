import * as React from 'react';
import { Progress } from 'antd';
import * as Styles from '../../authenticate/styles/index.module.less';
import * as Styles2 from './styles/index.module.less';

import Text from './text';
import SVGComponent from '../../authenticate/waves';

import { IDENTIFIERS } from '../../../config';
import PreloadProps from './props';

const BACKGROUND_URL: string =
  /* @__PURE__ */ '/static/img/backgrounds/background-01.jpg';
/**
 * `Login` or `Authenticate` component.
 * @deprecated TODO: Add `Firebase`
 */
export default function Preloader({ percent }: PreloadProps) {
  // eslint-disable-next-line
  if (percent === 100) percent = 50;
  // eslint-disable-next-line
  if (percent === null) percent = 100;
  /**
   * TODO: Check copyright.
   * Images are taken from
   * [https://www.vossloh-nordic.com/se/produkter-och-losningar/referensprojekt/11-nya-vaxlar-pa-39-timmar.html]
   * (https://www.vossloh-nordic.com/se/produkter-och-losningar/referensprojekt/11-nya-vaxlar-pa-39-timmar.html)
   */
  return (
    <div className={Styles2.preloader}>
      <div
        id={IDENTIFIERS.PAGE_HEADER}
        className={Styles.pageHeader}
        style={{
          backgroundImage: `url(${BACKGROUND_URL})`, // N.B: Add background image dynamically (and not hard code in the LESS)
        }}
      >
        <span className={Styles.backgroundGradient} />
        <div className={Styles.container}>
          <Text />
          <div className={Styles2.progress}>
            <Progress
              strokeColor="#33ae33"
              percent={percent}
              showInfo={false}
            />
          </div>
        </div>
        <div className={Styles.footer}>
          <SVGComponent />
        </div>
      </div>
    </div>
  );
}
